import {
  api,
  getTracksourceReport,
  fetchDataSourceList,
  getAppsFlyerPaReport,
  getAppsFlyerPaRate,
  getPidAppIdData,
} from 'api/report';
import Pagination from '@/components/pagination';
import optionData from '@/assets/js/optionData';
import GroupSelect from '@/components/GroupSelect';
import { getReportSubruleList } from 'api/product/subrule';
import { getPidsList } from 'api/product/rankSearch';
import { getPermissionAllListNew } from 'api/account/permission';
import dateUtils from '@/utils/dateutils.js';
import { filterObject } from 'utils/utils';
import { fetchCountryList } from '@/api/DDJ/v3/country';
import qs from 'querystring';
import { mapState, mapActions } from 'vuex';
import { myBlockMixin } from '@/mixins/blockmixins';
var clickTimer = null;
export default {
  mixins: [myBlockMixin],
  components: {
    GroupSelect,
    Pagination,
  },
  data() {
    return {
      // 筛选条件
      filter: {
        date: [],
        installDate: [],
        fromHour: '',
        toHour: '',
        sorting: 'install',
        sortType: 'desc',
        columns: [],
        ruleIds: [],
        sourceManagers: [],
        timezone: '',
        dataSourceIds: '',
      },
      dataSourceFilter: false,
      dataSourceFilterForm: {
        country: '',
        os: '',
      },
      paRate: 0,
      // 筛选参数状态
      filterCheckedStatus: [],
      // 列表数据
      list: [],
      // 列表数据
      palist: [],
      // 下拉框列表
      options: {
        timezone: optionData.timezoneOption,
        offerIds: [],
        platform: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'ANDROID',
            value: 'ANDROID',
          },
          {
            label: 'IOS',
            value: 'IOS',
          },
        ],
        subRule: [],
        pidList: [],
        country: [],
        pdDomains: [],
        clickSourceApps: [],
      },
      // 加载
      loading: {
        offerIds: false,
        list: false,
        search: false,
      },
      // 设置
      config: {
        fullWidth: document.documentElement.clientWidth,
      },
      pages: {
        total: 0,
        pageParam: {
          page: 1,
          pageSize: 20,
        },
      },
      dataSourceList: [],
      pidList: [],
      appIdList: [],
    };
  },
  mounted() {
    //日期默认选择
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24);
    end.setTime(end.getTime() - 3600 * 1000 * 24);
    this.filter.date = [];
    this.filter.date[0] = dateUtils.date2StrYMD(end);
    this.filter.date[1] = dateUtils.date2StrYMD(start);
    // 默认时区
    this.filter.timezone = '+00:00';
    // 默认columns
    // this.filter.columns.push('date');
    //sub rule
    this.subRuleList();
    this.managerList();
    // this.getPdDomains();
    this.getPids();
    this.getCountry();
    //获取Affiliate
    this.getAffiliateList();
    //获取Source
    this.getSourceIdsList();
    // 获取dataSource数据列表
    this.getDataSourceList();
    this.getPidAppidList();
  },
  computed: {
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
    // 计算 InstallRejectRate
    calcInstallRejectRate: () => {
      return (insRejectTotal, installs) => {
        // 规则 如果两个参数都为0的情况 不进行计算
        if (insRejectTotal == 0 && installs == 0) {
          return 0;
        } else {
          return new Number((insRejectTotal / (insRejectTotal + installs)) * 100).toFixed(2) + '%';
        }
      };
    },
  },
  watch: {
    filterCheckedStatus: function() {
      this.$nextTick(() => {
        this.$refs.trackSourceTableRef.doLayout();
      });
    },
    'filter.columns': function(v) {
      // this.filter.sorting = v.length > 0 ? 'date' : 'rejectionTotal';
      this.filter.sorting = v.length > 0 ? 'install' : 'install';
      this.filter.sortType = 'desc';
    },
  },
  methods: {
    // get Affiliate
    ...mapActions('affiliate', ['getAffiliateList']),
    // get Source
    ...mapActions('source', ['getSourceIdsList']),
    detailClick(row) {
      if (clickTimer) {
        window.clearTimeout(clickTimer);
        clickTimer = null;
      }
      var that = this;
      clickTimer = window.setTimeout(function() {
        that.$nextTick(() => {
          const { href } = that.$router.resolve({
            path: '/offer/detail',
            query: {
              offerId: row.offerId,
              country: row.countries,
              os: row.platforms,
            },
          });
          window.open(href, '_blank');
        });
      }, 300);
    },
    managerList() {
      getPermissionAllListNew().then((res) => {
        this.options.sourceManagers = res.result;
      });
    },
    // 国家
    getCountry() {
      fetchCountryList().then((res) => {
        this.options.country = res.result;
      });
    },
    getPids() {
      getPidsList().then((res) => {
        this.options.pidList = res.result;
      });
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex > 0 && rowIndex === this.list.length - 1) {
        return 'bgdfdfdf';
      }
    },
    // 拼接filter数据
    filterDataSet: function(e, page) {
      let param = {
        attributedTouchTimeFrom: this.filter.date[0],
        attributedTouchTimeTo: this.filter.date[1],
      };
      if (this.filter.installTime && this.filter.installTime.length > 0) {
        param.installTimeFrom = this.filter.installTime[0];
        param.installTimeTo = this.filter.installTime[1];
      }
      // 重置翻页
      if (page && page !== '') {
        this.pages.pageParam.page = page;
      }
      if (this.filter.timezone) {
        param.timezone = this.filter.timezone;
      }
      if (this.filter.offerIds) {
        param.offerIds = this.filter.offerIds;
      }
      if (this.filter.affiliateIds) {
        param.affiliateIds = this.filter.affiliateIds.toString();
      }
      if (this.filter.sourceIds) {
        param.sourceIds = this.filter.sourceIds.toString();
      }
      if (this.filter.countries) {
        param.countries = this.filter.countries;
      }
      if (this.filter.pkgs) {
        param.pkgs = this.filter.pkgs;
      }
      if (this.filter.platform) {
        param.platform = this.filter.platform;
      }
      if (this.filter.affSubs) {
        param.affSubs = this.filter.affSubs;
      }
      if (this.filter.sorting) {
        param.sorting = this.filter.sorting;
      }
      if (this.filter.sortType) {
        param.sortType = this.filter.sortType;
      }
      if (this.filter.pids?.length > 0) {
        param.pids = this.filter.pids.toString();
      }
      if (this.filter.pkgs?.length > 0) {
        param.pkgs = this.filter.pkgs.toString();
      }

      param.columns = this.filter.columns.concat(this.filterCheckedStatus).toString();
      if (param.columns.indexOf('affiliate_id') > -1) {
        param.columns = param.columns + ',company';
      }
      if (e === 'Search') {
        this.searchPaRepoertList(param);
        if (this.filter.countries && this.filter.date && this.filter.pids && this.filter.pkgs) {
          this.searchPaRate(param);
        }
      }
      if (e === 'Download') {
        this.exportExcelFileClick(param);
      }
    },
    // 筛选
    searchListClick(param) {
      let par = {
        ...this.pages.pageParam,
        ...param,
      };
      this.loading.list = true;
      this.loading.search = true;
      getTracksourceReport(par)
        .then((res) => {
          this.loading.list = false;
          this.loading.search = false;
          if (res.code === 200 && res.success === true) {
            let result = res.result || [];
            if (result.length === 0) {
              this.list = [];
              return;
            }
            this.list = [];
            result[result.length - 1].date = 'total';
            this.list = result;
            this.pages.total = res.total;
          } else {
            this.$message.error(res.message);
            this.list = [];
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$message.error('查询失败！' + error.toString());
          this.loading.list = false;
          this.loading.search = false;
        });
    },
    searchPaRepoertList(param) {
      let par = {
        ...this.pages.pageParam,
        ...param,
      };
      this.loading.list = true;
      this.loading.search = true;
      getAppsFlyerPaReport(par)
        .then((res) => {
          this.loading.list = false;
          this.loading.search = false;
          if (res.code === 200 && res.success === true) {
            let result = res.result || [];
            this.pages.total = res.total;
            if (result.length === 0) {
              this.palist = [];
              return;
            }
            this.palist = [];
            this.palist = result;
          } else {
            this.$message.error(res.message);
            this.palist = [];
          }
        })
        .catch((error) => {
          this.$message.error('查询失败！' + error.toString());
          this.loading.list = false;
          this.loading.search = false;
        });
    },
    searchPaRate(param) {
      let par = {
        ...this.pages.pageParam,
        ...param,
      };
      this.loading.list = true;
      this.loading.search = true;
      getAppsFlyerPaRate(par)
        .then((res) => {
          this.loading.list = false;
          this.loading.search = false;
          if (res.code === 200 && res.success === true) {
            let result = res.result || 0;
            this.paRate = result;
          } else {
            this.$message.error(res.message);
            this.palist = 0;
          }
        })
        .catch((error) => {
          this.$message.error('查询失败！' + error.toString());
          this.loading.list = false;
          this.loading.search = false;
        });
    },
    // 导出
    exportExcelFileClick(param) {
      // console.log(param);
      if (param) {
        param = filterObject(param);
        let strParam = qs.stringify(param);
        window.open(`${api.DOWNLOAD_APPSFLYER_PA_REPORT}?${strParam}`, '_blank');
      } /*else {
        this.$message.error('columns不能为空！');
      }*/
    },
    // 更改每页显示数
    handleSizeChange(size) {
      // console.log(size);
      this.pages.pageParam.pageSize = size;
      this.handleCurrentChange(1); // 更改每页显示数以后页码重置为0
    },
    // 点击数字翻页
    handleCurrentChange(currentPage) {
      this.pages.pageParam.page = currentPage;
      this.filterDataSet('Search');
    },
    // 排序监听
    sortChange(column) {
      // console.log(column);
      if (column.prop !== null && column.order !== null) {
        this.filter.sorting = column.prop;
        this.filter.sortType = column.order === 'ascending' ? 'asc' : 'desc';
      } else {
        this.filter.sorting = '';
        this.filter.sortType = '';
      }
      this.filterDataSet('Search');
    },
    // sub rule
    subRuleList() {
      getReportSubruleList().then((res) => {
        // console.log(res);
        this.options.subRule = res.result;
      });
    },
    checkIn(column) {
      let checked = false;
      this.filterCheckedStatus.forEach((item) => {
        if (item === column) {
          checked = true;
        }
      });
      return checked;
    },
    checkDate(column) {
      return this.filter.columns.indexOf(column) > -1;
    },

    getDataSourceList(data) {
      fetchDataSourceList(data).then((res) => {
        var dataSource = [];
        for (let i in res.result) {
          dataSource.push({ data_source_id: i, data_source_name: res.result[i] });
        }
        this.dataSourceList = dataSource;
        if (this.dataSourceFilter == true) {
          this.dataSourceFilter = false;
          this.$message.success('data Source 筛选成功');
        }
      });
    },
    dataSourceFilterSubmit() {
      this.getDataSourceList(this.dataSourceFilterForm);
    },
    getPidAppidList() {
      getPidAppIdData().then((response) => {
        let _this = this;
        this.pidList = [];
        this.appIdList = [];
        this.pidAppidObject = {};
        if (response.code === 200) {
          response.result.forEach((item, index) => {
            _this.pidAppidObject[item.pid] = item.appIds;
            _this.pidList.push(item.pid);
            const group1 = {
              id: index + 1,
              checked: false,
              label: item.pid,
              children: [],
            };
            item.appIds.forEach((item1) => {
              group1.children.push({
                id: item1,
                value: item1,
                label: item1,
              });
            });
            _this.appIdList.push(group1);
          });
        }
      });
    },
  },
};
