<template>
  <div class="trackSource">
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form :model="filter" label-width="80px" size="mini">
          <el-row justify="center">
            <el-col :span="7" style="height: 34px;">
              <el-form-item label="attributedTouchTime:" label-width="150px" class="mb5">
                <el-date-picker
                  v-model="filter.date"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :clearable="false"
                  class="w100"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="height: 34px;">
              <el-form-item label="installTime:" label-width="100px" class="mb5">
                <el-date-picker
                  v-model="filter.installTime"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  clearable
                  class="w100"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="Countries(s):" label-width="100px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input
                      v-model="filter.countries"
                      placeholder="eg. US,IT"
                      clearable
                    ></el-input>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="mediaSource(s):" label-width="90px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-select
                      v-model="filter.pids"
                      filterable
                      multiple
                      clearable
                      collapse-tags
                      placeholder="Please select"
                      class="w100"
                    >
                      <el-option
                        v-for="item in options.pidList"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="appid(s):" label-width="90px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <group-select
                      v-model="filter.pkgs"
                      :data-source="appIdList"
                      multiple
                      reserve-keyword
                      collapse-tags
                      clearable
                      filterable
                      placeholder="Please select"
                      class="w100"
                    />
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right">
              <el-form-item label-width="0px" class="mb0">
                <el-button type="primary" :loading="loading.search" @click="filterDataSet('Search')"
                  >Search</el-button
                >
                <el-button type="primary" @click="filterDataSet('Download')">Download</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="left">
              <div
                style="margin: 10px 20px;"
                class="ml10 font-16 cor337ab7"
                v-if="filter.countries && filter.date && filter.pids && filter.pkgs"
                >PA%: {{ paRate }}%</div
              >
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="palist"
          v-loading="loading.list"
          ref="appsflyerPa"
          style="width:100%;"
          size="mini"
          border
          highlight-current-row
          :row-class-name="tableRowClassName"
          @sort-change="sortChange"
        >
          <el-table-column label="appId" prop="appId" show-overflow-tooltip></el-table-column>
          <el-table-column label="appName" prop="appName" show-overflow-tooltip></el-table-column>
          <el-table-column
            label="originalUrl"
            prop="originalUrl"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="attributedTouchType"
            prop="attributedTouchType"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="attributedTouchTime"
            prop="attributedTouchTime"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="detectionDate"
            prop="detectionDate"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="installTime"
            prop="installTime"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="eventName"
            prop="eventName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="mediaSource"
            prop="mediaSource"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="channel" prop="channel" show-overflow-tooltip></el-table-column>
          <el-table-column
            label="country"
            prop="countryCode"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="ip" prop="ip" show-overflow-tooltip></el-table-column>
          <el-table-column
            label="fraudReasons"
            prop="fraudReasons"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="fraudSubReason"
            prop="fraudSubReason"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="filterCheckedStatus.indexOf('pid') > -1"
            label="pid"
            prop="mediaSource"
          ></el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="pages.total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pages.pageParam.pageSize"
          :currentPage="pages.pageParam.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import AppsflyerPaReportCon from '../controllers/report/appsflyerPaReport';
  export default {
    name: 'AppsflyerPaReport',
    ...AppsflyerPaReportCon,
  };
</script>

<style lang="scss" scoped></style>
